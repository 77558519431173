.c-page {
    &__title {
        margin-top: 0;
        width: 100%;
        padding: 8px;
        background-color: $green;
        color: $white;
    }
    &__title-desc {
        font-size: 14px;
        font-weight: 400;
    }
}