.c-photos {
    &__box {
        position: relative;
        width: 100%;
        padding-top: 60%;
        border: 1px solid $green;
    }

    &__box-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    &__delete {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 4px;
        right: 4px;
        z-index: 2;
        background-color: rgba($white, 0.8);
        width: 32px;
        height: 32px;
        border-radius: 100px;
        cursor: pointer;
        transition: 0.5s all ease-in-out;

        &:hover {
            transform: rotate(180deg);
        }
    }
}