.u-cf {
    &--black {
        color: $black;
    }
    &--blue {
        color: $blue;
    }
    &--grey {
        color: $grey-dark-light;
    }
    &--grey-dark {
        color: $grey-dark;
    }
    &--green {
        color: $green;
    }
    &--red {
        color: $red;
    }
    &--white {
        color: $white;
    }
}

.u-cb {
    &--black {
        background-color: $black;
    }
    &--blue {
        background-color: $blue;
    }
    &--grey {
        background-color: $grey-dark-light;
    }
    &--grey-dark {
        background-color: $grey-dark;
    }
    &--green {
        background-color: $green;
    }
    &--red {
        background-color: $red;
    }
    &--white {
        background-color: $white;
    }
}