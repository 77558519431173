.e-link {
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    
    &--white-green {
        color: $white-dark;

        &:hover {
            color: $green;
        }
    }

    &--grey-dark-light {
        color: $grey-dark;

        &:hover {
            color: $grey-dark-light;
        }
    }
}