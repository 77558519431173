/* font weight */
.u-fw {
    &--400 {
        font-weight: 400;
    }
    &--700 {
        font-weight: 700;
    }
}

.u-fa {
    &--left {
        text-align: left;
    }
    &--center {
        text-align: center;
    }
    &--right {
        text-align: right;
    }
}

.u-fs {
    &--14 {
        font-size: 14px;
    }
    &--16 {
        font-size: 16px;
    }
    &--18 {
        font-size: 18px;
    }
    &--20 {
        font-size: 20px;
    }
    &--24 {
        font-size: 24px;
    }
}