.u-flx {
    display: flex;

    &--fw {
        flex-wrap: wrap;
    }

    &--cx {
        justify-content: center;
    }

    &--cy {
        align-items: center;
    }

    &--cxy {
        align-items: center;
        justify-content: center;
    }
}